import React, { useState, useEffect } from 'react';
import Image from 'next/image';
//Load Styles

import styles from '../../styles/components/advertisment/AdvertismentUno.module.scss'

import {
  setCookieInMinutes,
  getCookie
} from '../../utils/cookieHelper'

import {
  Box,
  Modal,
  Typography
} from '@mui/material';
import {
  Button,
} from 'react-bootstrap';

function AdvertismentUno (props){
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      useEffect(() => {
        // console.log(`Show ad: ${props.showAdvertisment}`) 
        if(props.showAdvertisment===true)
        {  
          if(getCookie("showed-ad")==='true')
          {
            // Keep Ad closed
          }
          else
          {
          // console.log("Try to Open")
          setCookieInMinutes('showed-ad','true',15)
          setOpen(true)    
          }
        }
        else
        {
          // console.log("Cannot open showAdvertisment ===false!") 
        }

      },[props.showAdvertisment])
     

     return(
      <div>
        {/* Activate only for tesing 
        <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box 
          // sx={style}
           className={`${styles['advertismentBox']}`}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {props.title}     
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 0.5 }}>
              {props.subtitle}    
            </Typography>
            <Image priority
            width={`${props.media?.data.attributes.width}px`}
            height={`${props.media?.data.attributes.height}px`}
            alt="Advertisment" 
            src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${props.media?.data.attributes.url}`}
            >
            </Image>
            <Button className={`${styles['test']}`}
            href={`${props.callToActionURL}`}
            target={`${props.callToActionTarget}`}>{`${props.callToActionText}`}</Button>

          </Box>
        </Modal>
      </div>
    )
}


export default AdvertismentUno;