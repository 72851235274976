import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import styles from '../styles/Home.module.scss'
import { Container, Row, Col } from 'react-bootstrap';
// Components for building main Homepage
import Footer from '../components/common/footer';
import Header from '../components/common/header';
import Placeholder from '../components/common/placeholder';
import LoadingSpinnerUno from '../components/spinner/LoadingSpinnerUno';
import BasicSlider from '../components/slider/BasicSlider';
// import Posts from '../components/blog/Posts';
import Posts from '../components/blog/PostTwo';
// import Masonry from '../components/masonry/Masonry';
// import Masonry2 from '../components/masonry/Masonry2';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Concerts from '../components/concerts/Concerts';
import TestimonialSlider from '../components/testimonials/TestimonialSlider';
import AdvertismentUno from '../components/advertisment/AdvertismentUno';
import QuickLinkBox from '../components/elements/QuickLinkBox';
//i18n
import { useTranslation } from 'react-i18next';
// Custom NPM Package
import { BackToTopButton,CookieBanner } from '@benchvondaranch/npm.react-ui-library';
// Using Global Redux State
import { useSelector, useDispatch } from 'react-redux';
import { updateLocalStorage,selectImpressionsData } from '../store/userSlice';
import variables from '../styles/variables.module.scss';
import WhatsApp from '../components/integrations/WhatsApp';


export default function Home() {
  
   // const [isLoading, setIsLoading] = useState(true);//for prod
  const [isLoading, setIsLoading] = useState(true);//for test
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(true);
  // const [showAdvertisment, setShowAdvertisment] = useState(false);  
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [settingsData, setSettingsData] = useState({});

  const [impressionsData2, setImpressionsData2] = useState([]);
  
  // Load from Redux Stae
  const impressionsData = useSelector(selectImpressionsData);


  const [advertismentData, setAdvertismentData] = useState({});

  // const images = [
  //   '/assets/images/masonry/image1.avif',
  //   '/assets/images/masonry/image2.avif',
  //   '/assets/images/masonry/image3.avif',
  //   '/assets/images/masonry/image4.avif',
  //   '/assets/images/masonry/image5.avif',
  //   '/assets/images/masonry/image6.avif',
  //   '/assets/images/masonry/image7.avif',
  //   '/assets/images/masonry/image8.avif',
  //   '/assets/images/masonry/image9.avif',
  //   '/assets/images/masonry/image10.avif',
  // ];

  const getAdvertismentData = async () => {
    try {
     
      if(settingsData.General?.show_advertisment_on_home_page === false)
      {
        debugger
        console.log('Showing Ads disabled on HP')
      }
      else
      {
      const response = await fetch(`/api/backend/advertisment?locale=${i18n.language}`, {
      headers: {
        Accept: "application/json",
      }
      });
      const advertismentDataFromBackend = await response.json();
      setAdvertismentData(advertismentDataFromBackend);
      }
    }
    catch (error) {
      console.log('[Error loading Advertisment Data] - ' + error);      
    }
  }


  const getSettingsData = async () => {
        
    try {
          const response = await fetch(`/api/backend/settings?locale=${i18n.language}`, {
          headers: {
            Accept: "application/json",
          },
    });
    
    const settingsDataFromBackend = await response.json();
    setSettingsData(settingsDataFromBackend);
    } catch (error) {

        console.log('[Error - loading Settings] - ' + error);

    }
    finally{
        setIsLoading(false)
    }
  };

 

  useEffect(() => {       
    async function fetchDataFromNextAPI() {           
        await getSettingsData();  
        // await getAdvertismentData();
                       
      }      
      fetchDataFromNextAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])


// useEffect(() => {    
  
//   if(images.length > 0 )
//   {
//     setAllImagesLoaded(true);
//   }
   
// },[allImagesLoaded])

  // useEffect(() => {
  //   //Simulate long loading in miliseconds
  //   //For Production Usage set to Minimum! 
  //   // setTimeout(() => setIsLoading(false), 100);
    
  // },[])


  useEffect(() => {

   
    if (impressionsData.length === 0) {
      // Fetch data only if not available in Redux store
      getImpressionsData();

    }
    else{
      console.log("loaded data from redux store...");
      setIsLoading(false);
    }
  }, [impressionsData]);

  const getImpressionsData = async () => {        
    try {
          const response = await fetch(`/api/backend/impressions?locale=${i18n.language}`, {
          headers: {
            Accept: "application/json",
          },
    });    
    const dataFromBackend = await response.json();
    // setData(dataFromBackend);
    setImpressionsData2(dataFromBackend);
    dispatch(setImpressionsData(dataFromBackend)); // Store data in Redux store

    } catch (error) {
        console.log('[Error - loading Impressions Data] - ' + error);
    }
    finally{
        setIsLoading(false)
    }
  };



  useEffect(() => {
  if (typeof window !== 'undefined') {
    // console.log(JSON.stringify(settingsData));
    // console.log('Checking if GDPR was alreday accepted...')
     const cookieBannerDataFromLocalStorage = localStorage.getItem('cookieBannerData') ? JSON.parse(localStorage.getItem('cookieBannerData')) : undefined;
     
     if(cookieBannerDataFromLocalStorage?.accepted==="true")
     {
      // console.log('GDPR is Accepted: Disable Cookie Banner and show Advertisment if necessary...')
      // Accept all is not too far away
      setShowCookieBanner(false);
      // setShowAdvertisment(true);
     }
     else{
      // console.log('Seems acceptance is still missing')
     }
     // can use localStorage here
    } else {      
      // can't use localStorage
    }
  },[settingsData])

  const selectAll = (e) => {
    const cookieData = {
      "necessary":"true",
      "marketing":"true",
      "performance":"true",
      "accepted":"true",
      "acceptedDateTime":`${new Date()}`,
      "savedViaButtonAction":`${e.target.getAttribute('data-c-action')}`,      
    }
    localStorage.setItem('cookieBannerData', JSON.stringify(cookieData));
    // setCookieBannerData(cookieData)
    dispatch(updateLocalStorage(cookieData))
    setShowCookieBanner(false);
    // setShowAdvertisment(true);    
  }

  const saveSelection = (e) => {
    // Get All Checkboxes of CookieBanner
    const cbNecessary = document.getElementById('necessary').checked;
    const cbMarketing = document.getElementById('marketing').checked;
    const cbPerformance = document.getElementById('performance').checked;
    const cookieData = {
      "necessary": `${cbNecessary}`,
      "marketing":`${cbMarketing}`,
      "performance":`${cbPerformance}`,
      "accepted":"true",
      "acceptedDateTime":`${new Date()}`,
      "savedViaButtonAction":`${e.target.getAttribute('data-c-action')}`,      
    }
    localStorage.setItem('cookieBannerData', JSON.stringify(cookieData));
    dispatch(updateLocalStorage(cookieData))
    setShowCookieBanner(false);
    // setShowAdvertisment(true);  
  }

  

  if (isLoading===true) return null;

  return (

    <React.Suspense fallback="loading">
      
    <div className={styles.container} id="page-container" >
     {
       isLoading === false ? 
       <>
      <Head>
        <title>{t('meta_tag_home_page_title')}</title>
        <meta name="description" content={t('meta_tag_home_page_description')} />
        <link rel="icon" href="/favicon.ico" />
      </Head>


      <Header  />
     <BasicSlider />
     {/* <Placeholder/> */}
     
        <Container className="pt-4" fluid >
        <Row className='justify-content-center webpageTitle' style={{
          backgroundColor:variables.initial_standardHeaderBanner_backgroundColor,
          color:variables.initial_standardHeaderBanner_fontColor,
          fontFamily:"CintaSehatti",
          }}>
          <Col></Col>
          <Col  xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
            <h2 >Meine Welt</h2>
          </Col>
          <Col></Col>
        </Row>  
          </Container>
     
     
     <Posts></Posts>

     <Container className="pt-4" fluid >
        <Row className='justify-content-center webpageTitle' style={{
          backgroundColor:variables.initial_standardHeaderBanner_backgroundColor,
          color:variables.initial_standardHeaderBanner_fontColor,
          fontFamily:"CintaSehatti",
          }}>
          <Col></Col>
          <Col  xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
            <h2 >Impressionen</h2>
          </Col>
          <Col></Col>
        </Row>  
          </Container>


        <Container fluid>
        <Masonry columnsCount={4} gutter="0px" style={{marginTop:'0px'}}>
                  {
                  impressionsData2.map((image, i) => (
                  
                      <img
                          key={i}
                          src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${image.attributes.formats.medium.url}`}
                          style={{width: "100%", display: "block"}}
                      />
                  ))}
              </Masonry>        
        </Container>   
     
        <TestimonialSlider></TestimonialSlider>
  
     <Container className="pt-4" fluid >
      <Row className='justify-content-center webpageTitle' style={{
        backgroundColor:variables.initial_standardHeaderBanner_backgroundColor,
        color:variables.initial_standardHeaderBanner_fontColor,
        fontFamily:"CintaSehatti",
        }}>
        <Col></Col>
        <Col  xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
          <h2 >Hier bin ich bald</h2>
        </Col>
        <Col></Col>
      </Row>  
      </Container>
      <Concerts 
      getNextXConcerts={3}
      showFilter={false}
      showSorting={false}
      limitToNumber={3}
      sortingMechanism={"concertDate:asc"}
      showOnlyPastConcerts={false}
      showOnlyFutureConcerts={true}
      ></Concerts>


<QuickLinkBox></QuickLinkBox>

    

     <Footer settings={settingsData.Footer} />
     

       {/* { advertismentData !== null ?
        <AdvertismentUno 
        showAdvertisment={showCookieBanner === false && settingsData.General?.show_advertisment_on_home_page === true ? true : false}
        title={advertismentData.title}
        subtitle={advertismentData.subtitle}
        media={advertismentData.media}
        callToActionText={advertismentData.call_to_action_text}
        callToActionURL={advertismentData.call_to_action_url}
        callToActionTarget={advertismentData.call_to_action_target}        
        />
        : ""
       } */}

      </>
      :
       <LoadingSpinnerUno></LoadingSpinnerUno>
    }
    </div>
    <CookieBanner
      title={ t("cookie_banner_title") }
      subtitle={ t("cookie_banner_subtitle") }
      description={ t("cookie_banner_description") }
      text_save_selection={ t("cookie_banner_text_save_selection") }
      text_accept_all={ t("cookie_banner_text_accept_all") }
      text_checkbox_option1={ t("cookie_banner_text_checkbox_option1") }
      text_checkbox_option2={ t("cookie_banner_text_checkbox_option2") }
      text_checkbox_option3={ t("cookie_banner_text_checkbox_option3") }
      gdpr_link={ t("cookie_banner_gdpr_link") }
      gdpr_link_text={ t("cookie_banner_gdpr_link_text") }
      imprint_link={ t("cookie_banner_imprint_link") }
      imprint_link_text={ t("cookie_banner_imprint_link_text") }
      display={showCookieBanner}
      handleAcceptAllClick={selectAll}
      handleSaveSelectionClick={saveSelection}
      ></CookieBanner>
      {/* <WhatsApp></WhatsApp> */}

      

    </React.Suspense>
  )
}
