import { useState, useEffect,useRef  } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
//Load Styles
import styles from '../../styles/components/testimonials/TestimonialSlider.module.scss'



const TestimonialSlider = () => {
  const slides = [
    {
      title: 'Ein Meister der Tasten',
      quote: 'Ich hatte das Privileg, diesen talentierten jungen Mann live zu erleben, und ich war absolut begeistert von seiner Performance. Sein Spiel war technisch makellos und doch so leidenschaftlich und emotional. Er versteht es, die Töne zum Leben zu erwecken und das Publikum in seinen Bann zu ziehen. Ein wahrhaft außergewöhnlicher Künstler!',
      author: 'Manfred Bockelmann',
      authorAddress: 'Kärnten, Österreich',
      profileImage: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    },
    {
      title: 'Er schafft eine einzigartige Verbindung zur Musik',
      quote:
        'Ich bin begeistert von der Fähigkeit, eine einzigartige Verbindung zur Musik herzustellen. Jedes Mal, wenn er die Tasten berührt, spürt man seine Hingabe und Liebe zur Kunst. Seine Interpretationen sind tiefgründig und berührend, und er vermag es, eine ganz besondere Atmosphäre zu schaffen. Ein absolutes Genie am Klavier!',
      author: 'Konstantin Wecker',
      authorAddress: 'Bayern, Deutschland',
      profileImage:
        'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    },
    {
      title: 'Entführt das Publikum in eine Welt voller Emotionen',
      quote:
        'Ich hatte das Privileg, den talentierten Pianisten Simon Stadler live zu erleben, und ich war absolut begeistert von seiner Performance. Sein Spiel war technisch makellos und doch so leidenschaftlich und emotional. Er versteht es, die Töne zum Leben zu erwecken und das Publikum in seinen Bann zu ziehen. Ein wahrhaft außergewöhnlicher Künstler!',
      author: 'Seppi Steiner',
      authorAddress: 'Kärnten, Österreich',
      profileImage:
        'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    },
  ];

  
  const [curSlide, setCurSlide] = useState(0);
  //const [curSlide, setCurSlide] = useState(0);
  //const allSlides = Array.from(document.querySelectorAll('.slide'));
  // const maxSlide = slides.length;
  const intervalDuration = 15000; 
  // const testimonialsContainer = document.querySelector('.testimonials');
  const testimonialsContainerRef = useRef(null); // Ref for the testimonials container
  const touchStartXRef = useRef(null); // Ref for touch start X position

// added Testimonials
  const  [testimonials, setTestimonials] = useState([]);
  const  [testimonialsMaxSlide, setTestimonialsMaxSlide] = useState(0);
  
  const getData = async () => {        
    try {
      
          const response = await fetch(`/api/backend/testimonials`, {
          headers: {
            Accept: "application/json",
          },
          });    
    const settingsDataFromBackend = await response.json();

    setTestimonials(settingsDataFromBackend);
    setTestimonialsMaxSlide(settingsDataFromBackend.length);

    } catch (error) {
        console.log('[Error - loading Testimonials] - ' + error);
    }
    finally{
        // setIsLoading(false)
    }
  };

 

  useEffect(() => {       
      async function fetchDataFromNextAPI() {   
        getData();                 
        }      
        fetchDataFromNextAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])




  useEffect(() => {

    const testimonialsContainer = testimonialsContainerRef.current; // Access the container element using the ref

    const autoplay = setInterval(() => {
      nextSlide();
    }, intervalDuration);

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') prevSlide();
      if (e.key === 'ArrowRight') nextSlide();
    };

    const handleMouseEnter = () => {
      
      clearInterval(autoplay);
      autoplay = null;
    };

    const handleMouseLeave = () => {
      if (!autoplay) {
        autoplay = setInterval(() => {
          nextSlide();
        }, intervalDuration);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    testimonialsContainer.addEventListener('mouseenter', handleMouseEnter);
    testimonialsContainer.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      clearInterval(autoplay);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mouseenter', handleMouseEnter);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);


  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX; // Store the touch start position
  };

  const handleTouchMove = (e) => {
    if (!touchStartXRef.current) return; // Do nothing if touch start position is not set

    const touchCurrentX = e.touches[0].clientX;
    const touchDiffX = touchCurrentX - touchStartXRef.current;

    if (touchDiffX > 10) {
      // Swipe to the right
      prevSlide();
    } else if (touchDiffX < -10) {
      // Swipe to the left
      nextSlide();
    }

    touchStartXRef.current = null; // Reset touch start position
  };

  const nextSlide = () => {
 
    setCurSlide((prevSlide) => {
      
      let prevLideIndex = prevSlide;
      // return prevSlide === maxSlide - 1 ? 0 : prevSlide + 1
      return prevSlide === testimonialsMaxSlide - 1 ? 0 : prevSlide + 1
    
    });
  };

  const prevSlide = () => {
    // setCurSlide((prevSlide) => (prevSlide === 0 ? maxSlide - 1 : prevSlide - 1));
    setCurSlide((prevSlide) => (prevSlide === 0 ? testimonialsMaxSlide - 1 : prevSlide - 1));
  };

  const handleDotClick = (slide) => {
    setCurSlide(slide);
  };

  

  return (
    // <section className={`${styles['section']}`} id="section--3">
      <>
      <Container  fluid >
      <Row className='justify-content-center' style={{
        backgroundColor: 'black',
        color:'white',
        fontFamily:"CintaSehatti",
        paddingTop: '5rem',
        paddingBottom: '0.8rem'
        }}>
        <Col></Col>
        <Col  xs={'auto'} sm={'auto'} md={'auto'}  lg={'auto'}  xl={'auto'}>
          <h2 >Was man über mich sagt</h2>
        </Col>
        <Col></Col>
      </Row>  
        </Container>
      <div className={`${styles['slider']}`}
      ref={testimonialsContainerRef}
      onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={() => (touchStartXRef.current = null)}>
        {/* <h2 >Was man über mich sagt</h2> */}
        {/* {slides.map((slide, index) => ( */}
       { (testimonials.length === 0) ? null :
       <>
        {testimonials.map((slide, index) => (
          
          <div
            className={`${styles['slide']} ${index === curSlide ? `${styles['active']}` : ''}`}
            key={index}
            style={{
              transform: `translateX(${100 * (index - curSlide)}%)`,
              transition: 'transform 0.5s ease',
            }}
          >
            <div className={`${styles["testimonial"]}`}>
              <h5 className={`${styles["testimonial__header"]}`}>{slide.attributes.title}</h5>
              <blockquote className={`${styles["testimonial__text"]}`}>{slide.attributes.quote}</blockquote>
              <address className={`${styles["testimonial__author"]}`}>
                <img
                  // src={slide.attributes.profileImage}
                  src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${slide.attributes.profileImage.data.attributes.formats.medium.url}`}
                  alt={slide.attributes.author}
                  className={`${styles['testimonial__photo']}`}
                />
                <h6 className={`${styles["testimonial__name"]}`}>{slide.attributes.author}</h6>
                <p className={`${styles["testimonial__location"]}`}>{slide.attributes.authorAddress}</p>
              </address>
            </div>
          </div>
        ))}
        </>
      }
        {/* <button className={`${styles['slider__btn']} ${styles['slider__btn--left']}`} onClick={prevSlide}>
          &larr;
        </button>
        <button className={`${styles['slider__btn']} ${styles['slider__btn--right']}`} onClick={nextSlide}>
          &rarr;
        </button> */}
        <div className={`${styles['dots']}`}>
          {testimonials.map((_, index) => (
            <button
              className={`${styles['dots__dot']} ${index === curSlide ? `${styles['dots__dot--active']}` : ''}`}
              key={index}
              onClick={() => handleDotClick(index)}
            ></button>
          ))}
        </div>
      </div>  
     </>
  );
};

export default TestimonialSlider;
