import React, { useState, useEffect } from 'react';
import Image from 'next/image';
//Load Styles
import styles from '../../styles/components/spinner/LoadingSpinnerUno.module.scss'

//https://projects.lukehaas.me/css-loaders/#load8

function LoadingSpinnerUno (){
   
     return(
      <div style={{
        backgroundColor:"black",
        height:"100vh",
        position:"relative",
        overflow: "hidden",
      }} >
        <div
    style={{
      display: "flex",
      justifyContent: "center",     
      marginTop:"40vh",      
      marginLeft:"45vw",      
      position:"absolute",
      overflow: "hidden",
    }}
  >   
        <div  style={{
      display: "flex",
      justifyContent: "center", }} className={styles.loader}></div>
      </div>
      </div>
)
}

export default LoadingSpinnerUno;