import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { Container, Row, Col, Card, Placeholder, Button } from 'react-bootstrap';
// Swiper
import SwiperCore, {
  Navigation,
  Pagination, 
  Scrollbar, 
  A11y, 
  Autoplay,
  EffectFlip,
  EffectCoverflow,
  Mousewheel,
  Lazy
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled, {css} from 'styled-components';
//Load Styles
import styles from '../../styles/components/slider/BasicSlider.module.scss'
//i18n
import { useTranslation } from 'react-i18next';
// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';
// import 'swiper/scss/scrollbar';

  export const CallToActionButton = styled(({
    color, 
    backgroundColor,
    textColor, 
    hoverTextColor, 
    hoverBackgroundColor,
    textSize,
    hoverTextSize,
    border,
    hoverBorder, 
    width, ...props}) => <Button {...props} />)`
  ${(p = props) =>
     css`
       color: ${p.color ? p.color : "white"};
       background-color: ${p.backgroundColor ? p.backgroundColor : 'red'} !important;
       font-size: ${p.textSize ? p.textSize : '2em'} !important;
       width: ${`${p.width}px` ? `${p.width}px` : '-webkit-fill-available'};
       border: ${p.border ? p.border : 'none'} !important;
       /*animation: example1 0.5s linear;*/
       

       &:hover, &:focus{
         color: ${p.hoverTextColor ? p.hoverTextColor : "white"};
         background-color: ${p.hoverBackgroundColor ? p.hoverBackgroundColor : 'red'} !important;
         font-size: ${p.hoverTextSize ? p.hoverTextSize : '2em'} !important;
         border: ${p.hoverBorder ? p.hoverBorder : 'none'} !important;
       }
       &:after{
         box-shadow: none !important;
       }

       /* Move it (define the animation) */
        @keyframes example1 {
        0%   { 
        transform: translateX(200%); 		
        }
        100% { 
        transform: translateX(0%); 
        }
        }

   `} ` 

function BasicSlider (){
  const swiperRef = React.useRef(SwiperCore);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    
    try {
      // fetch Slider Items  
      const response = await fetch(`/api/backend/sliderItems?locale=${i18n.language}`, {
        headers: {
          Accept: "application/json",
        },
      });
    
      const fetchedData = await response.json();      
      setData(fetchedData);
      
    } catch (error) {

      console.log('[Error - BasicSlider Component] - ' + error);
      
    }
    finally{
      setIsLoading(false)
    }
  };


  useEffect(() => {
    //Simulate long loading in miliseconds
    //For Production Usage set to Minimum! 
    setTimeout(() => setIsLoading(false), 1000);

      async function fetchDataFromNextAPI() {           
        fetchData();                 
      }      
      
      fetchDataFromNextAPI();
    
  },[i18n.language])

  useEffect(() => {
    // Function to add the `muted` property to all video elements
    const addMutedProperty = () => {
      const videoElements = document.querySelectorAll('video');
      console.log('Loaded all Video elements');
      videoElements.forEach((video) => {
        debugger;
        // video.muted = true;
        video.setAttribute("muted",'')
        debugger;
      });
    };

   // Call the function when isLoading is false
   if (!isLoading) {
   
    addMutedProperty();
  }
    // Clean up the effect if needed
    return () => {
      // Perform any cleanup here
    };
  }, [isLoading]); // Empty dependency array ensures the effect runs only once


  const onInit = (Swiper) => {
    swiperRef.current = Swiper;
  };
  const handleMouseEnter = () => {
    if (swiperRef.current) swiperRef.current.autoplay?.stop();
  };
  const handleMouseLeave = () => {
    if (swiperRef.current) swiperRef.current.autoplay?.start();
  };



  if (isLoading===true) return null;

  
  return(
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
         {
            isLoading === false ?   
     <Swiper
     onInit={onInit}
     className={`${styles.teste}`}
      // install Swiper modules
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectCoverflow,Mousewheel,Lazy]}
      autoplay={{ delay: 50000}}
      // effect={"flip"}
      loop={true}
      speed={1500}
      lazy={true}
      // effect={"coverflow"}
      coverflowEffect= {{
        modifier: 0.1,
        rotate: 130,
        slideShadows: true,
      }
      }
      // mousewheel={true}
      // disableOnInteraction={true}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      {
      data.map((item, index) => (

        item.attributes.media_type ==="video" ?
      <SwiperSlide 
      key={`${item.id}`}
      className={styles['swiper-slide']}>
        <video 
        className={`${styles.video} lazy`}
        loop="loop"
        muted={true}
        autoPlay="autoplay"
        playsInline
        controls
        controlsList="nodownload">
            {/* If videos are statically presented see example below: */}
            {/* <source type="video/mp4" src="/assets/videos/demo1.mp4"/> */}
            <source type="video/mp4" src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${item.attributes.media.data.attributes.url}`}/>
            Your browser does not support the Video.
        </video>
        <div className={styles.overlay}>
              {
                item.attributes.show_heading === true ?
                <h1  className={styles['headingOne']} style={item.attributes.css_heading}>{item.attributes.heading}</h1>
                :
                <></>
              }
							{
                item.attributes.show_subheading === true ?
                <h2 className={styles['headingTwo']} style={item.attributes.css_subheading}>{item.attributes.subheading}</h2>
                :
                <></>
              }								
              {
                item.attributes.show_button === true ?
                <a href={item.attributes.button_url} target={item.attributes.button_url_target} >
                 <CallToActionButton
                  backgroundColor={item.attributes.call_to_action?.background_color}
                  hoverBackgroundColor={item.attributes.call_to_action?.hover_background_color}
                  textColor={item.attributes.call_to_action?.text_color}
                  textSize={item.attributes.call_to_action?.text_size}
                  hoverTextSize={item.attributes.call_to_action?.hover_text_size}
                  hoverTextColor={item.attributes.call_to_action?.hover_text_color}            
                  border={item.attributes.call_to_action?.border}            
                  hoverBorder={item.attributes.call_to_action?.hover_border}            
                 >
                  {item.attributes.button_text}
                 </CallToActionButton></a> : ''
                 
                
              }
				</div>
      </SwiperSlide>
      :
      <SwiperSlide
      key={`${item.id}`}
      className={styles['swiper-slide']}>
        {/* <img 
        src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${item.attributes.media.data.attributes.url}`}
        title={item.attributes.heading}
        alt={item.attributes.heading}
        /> */}
        <Image priority
        //  width={`${data.attributes.image.data.attributes.formats.small.width}px`}
        //  height={`${data.attributes.image.data.attributes.formats.small.height}px`}
        layout='fill'
         title={item.attributes.heading}
         alt={item.attributes.heading}
         src={`${process.env.NEXT_PUBLIC_BACKEND_API_URL}${item.attributes.media.data.attributes.url}`}
         />
        <div className={styles.overlay}>
              {
                item.attributes.show_heading === true ?
                <h1  className={styles['headingOne']} style={item.attributes.css_heading}>{item.attributes.heading}</h1>
                :
                <></>
              }
							{
                item.attributes.show_subheading === true ?
                <h2 className={styles['headingTwo']} style={item.attributes.css_subheading}>{item.attributes.subheading}</h2>
                :
                <></>
              }
              {
                item.attributes.show_button === true ?
                <a href={item.attributes.button_url} target={item.attributes.button_url_target} >
              <CallToActionButton
                 backgroundColor={item.attributes.call_to_action?.background_color}
                 hoverBackgroundColor={item.attributes.call_to_action?.hover_background_color}
                 textColor={item.attributes.call_to_action?.text_color}
                 textSize={item.attributes.call_to_action?.text_size}
                 hoverTextSize={item.attributes.call_to_action?.hover_text_size}
                 hoverTextColor={item.attributes.call_to_action?.hover_text_color}            
                 border={item.attributes.call_to_action?.border}            
                 hoverBorder={item.attributes.call_to_action?.hover_border}            
                 >
                  {item.attributes.button_text}
                 </CallToActionButton></a> : ''
               
              }
				</div>
      </SwiperSlide >
      ))}
      {/* <SwiperSlide className={styles['swiper-slide']}>        
        <img src="/assets/images/slider/1.jpg" />          
      </SwiperSlide>
      <SwiperSlide className={styles['swiper-slide']}>
        <img src="/assets/images/slider/1.jpg" />
      </SwiperSlide > */}
      {/* <SwiperSlide className={styles['swiper-slide']}>
        <img src="/assets/images/slider/1.jpg" />
        <div className={styles['swiper-slide-inner-container']}>
           <h1 className={styles['headingOne']}>
              <span>always be</span>
              <div className={styles['message']}>
                <div className={styles['word1']}>close</div>
                <div className={styles['word2']}>code</div>
                <div className={styles['word3']}>creating</div>
              </div>
            </h1>
        </div>
      </SwiperSlide> */}
      
      
    </Swiper> 
    :' waiting for Slider data'
    }
    </div>
)
}

export default BasicSlider;